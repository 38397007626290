.panel-wrapper {
  margin: 0 30px;

  .mat-accordion .mat-expansion-panel.company-accordion {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: none;
    margin-bottom: 20px;

    .mat-expansion-panel-header {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      font-family: 'cairo-bold';
      height: 53px;
      background-color: #CED1D6;
      font-size: 15px;

      &:hover {
        background: #707585;
      }
    }
  }

  .panel-body.company-panel {
    padding: 20px 0 0;
  }
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: #707585 !important;

  .mat-expansion-panel-header-title {
    img{
      filter: brightness(0) invert(1);
    }
  }
}

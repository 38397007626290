    .page-title {
        margin-bottom: 0px;
    }

    .request-control {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        padding: 30px 35px 0px 35px;
        margin-bottom: 20px;
        margin-top:60px;
        .breadcrumb-custom {
            margin-bottom: 0px;
        }
        .top-search {
            min-width: 340px;
        }
        .left-side, .right-side {
            display: flex;

            .left-side {
                gap: 10px;
            }
        }
        @media screen and (max-width: $min-md - 1) {
            flex-wrap: wrap;
            margin-bottom: 12px;

            .top-search {
                min-width: 100% !important;
                margin-top: 12px;
            }
        }
        .delete-icon {
            width: 42px;
            background-color: #FFFFFF;
            margin-left: 14px;
            border-radius: 5px;
            position: relative;
            right: 1px;
            img{
                position: absolute;
                padding:12px;
            }
            button {
                border-color: transparent;
                background-color: #fff;
                &:focus{
                    border-color: transparent;
                    background-color: #fff;
                }
            }
        }
        #request_table_filter {
          margin-bottom: 0 !important;
          img {
              position: absolute;
              top: 13px;
              right: 20px;
          }
        }
        #search_query {
            width: 100px;
            background-color: #FFFFFF;
            padding-right:46px;
            padding-left:5px;
            -webkit-transition: width 1s ease-in-out;
            -moz-transition:width 1s ease-in-out;
            -o-transition: width 1s ease-in-out;
            transition: width 1s ease-in-out;
           float:right;
           border: none !important;
           &:focus {
               width: 300px;
               background-color: #FFFFFF;
           }
          }
    }

    .saved-filters {
        padding: 0 35px;
    }
    
    .request-table {
        overflow: hidden;
        padding:0px 35px 0 35px;
        .table-responsive {
            border-radius: 4px 4px 0px 0px;
            border: 1px solid #DEE1EC;
        }

        table.table {
            margin-bottom: 0px;

            thead {
                tr {
                    th {
                        border-bottom: 1px solid $lighter-grey;
                    }
                }
            }
        }
    }

@import "../components/var";

.checkbox-container {
  display: block;
  position: relative;
  padding-right: 35px;
  margin-bottom: 0 !important;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    right: 0;
    top: 4px;
    cursor: pointer;
  }
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  right: 0;
  height: 16px;
  width: 16px;
  background-color: $white;
  border: 1px solid $grey-4;
  border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
  color: $blue-primary;

}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked + .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid #2196F3;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-label {
  margin-right: 25px;
  font-family: 'cairo-regular', sans-serif;
}

.label-desc {
  position: absolute;
  left: 10px;
  color: #A0A3AE;
  font-size: 13px;
}

// grid 
.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked,
.ag-theme-alpine .ag-checkbox-input-wrapper input {
  border: 1px solid $grey-4 !important;
  border-radius: 3px !important;
}

.ag-theme-alpine .ag-checkbox-input-wrapper {
  border: 1px solid $grey-4 !important;
  border-radius: 3px !important;
}

.ag-theme-alpine .ag-checkbox-input-wrapper:focus-within {
  box-shadow: none !important;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  color: #fff !important;
  content: '' !important;
  position: absolute;
  left: 4.5px !important;
  top: 2px !important;
  width: 5px;
  height: 8px;
  border: solid #2196F3;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-control.ng-invalid:not(.ng-untouched),
.required-error {
  border: 1px solid #D9727F;
  border-radius: 5px;
}

.ng-select.ng-invalid:not(.ng-untouched) .ng-select-container {
  border-color: #D9727F;
}

button:focus {
  outline: none;
}

.input-group {
  &:not(.has-validation)>.form-control:not(:last-child) {
    border-radius: 0 5px 5px 0;
  }

  input {
    border-radius: 0 5px 5px 0;
  }

  .input-group-append {
    .input-group-text {
      border-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
